import { Capacitor } from '@capacitor/core';
import { isPlatform } from '@ionic/vue';
import router from '@/router';
import { HOSTS_WITH_NO_REDIRECT } from '@/services/config/hostsWithNoRedirect';
import { NetworkModel } from '@/types/network';
import { isLocalhost } from '@/services/checkers';

type RedirectConfig = {
  currentHost: string;
  targetHost: string;
  domainType: 'Generic' | 'Custom';
  authType: 'Form' | 'Enterprise' | 'External';
  isNetworkSwitch: boolean;
};

type IUseRedirect = {
  isRedirectBlocked: () => boolean;
  shouldRedirect: (config: RedirectConfig) => boolean;
  /**
   * Redirects to custom domain from NetworkModel
   *
   *
   * @note After redirection, app will catch `/auth` in
   * @see src/router/index.ts - `_handleAuth()` (line 506)
   */
  redirectToCustomDomain: (network: NetworkModel) => void;
};

/** @see docs/AUTH_MATRIX.md */
export function useRedirect(): IUseRedirect {
  //#region Private methods
  function _handleGenericDomain(config: RedirectConfig): boolean {
    switch (config.authType) {
      case 'Form':
        // Network switches override normal checks EXCEPT host blocklist
        if (config.isNetworkSwitch) {
          console.debug('[Redirect] Generic Form Auth with Network Switch');
          return true; // Host blocklist already checked earlier
        }
        const shouldRedirect = !HOSTS_WITH_NO_REDIRECT.includes(config.currentHost);
        console.debug(`[Redirect] Generic Form Auth: ${shouldRedirect}`);
        return shouldRedirect;
      case 'Enterprise':
      case 'External':
        console.debug('[Redirect] Generic Enterprise/External Auth: blocked');
        return false;
    }
  }

  function _handleCustomDomain(config: RedirectConfig): boolean {
    // All custom domain types allow redirect only when switching networks
    const shouldRedirect = !!config.isNetworkSwitch;
    console.debug(`[Redirect] Custom Domain: ${shouldRedirect} (networkSwitch: ${config.isNetworkSwitch})`);
    return shouldRedirect;
  }
  //#endregion

  //#region Public methods
  function isRedirectBlocked(): boolean {
    return Capacitor.isNativePlatform() || isPlatform('electron');
  }

  function shouldRedirect(config: RedirectConfig): boolean {
    try {
      // If universal blocking conditions apply, no redirect
      if (isRedirectBlocked()) {
        console.debug('[Redirect] Blocked by universal conditions');
        return false;
      }

      // If current host is same as target, no redirect
      if (config.currentHost === config.targetHost) {
        console.debug('[Redirect] Current host same as target');
        return false;
      }

      // If current host is in no-redirect list, no redirect
      if (HOSTS_WITH_NO_REDIRECT.includes(config.currentHost)) {
        console.debug('[Redirect] Host in no-redirect list');
        return false;
      }

      // Handle Generic Domain cases
      if (config.domainType === 'Generic') {
        return _handleGenericDomain(config);
      }

      // Handle Custom Domain cases
      if (config.domainType === 'Custom') {
        return _handleCustomDomain(config);
      }

      return false;
    } catch (error) {
      console.error('[Redirect] Error in shouldRedirect:', error);
      return false;
    }
  }

  function redirectToCustomDomain(network: NetworkModel): void {
    const redirectParam = router.currentRoute.value.query.redirect;
    const redirectUrl = redirectParam ? String(redirectParam) : null;

    let finalRedirectUri = network.redirectUri;

    // On localhost, modify host, protocol and port but keep everything else
    if (isLocalhost()) {
      const url = new URL(finalRedirectUri);
      url.protocol = window.location.protocol;
      url.host = window.location.host;
      url.port = '8210'; // TEST
      finalRedirectUri = url.toString();
      console.log('[INFO] Mock mode enabled: redirecting to localhost instead of actual domain');
    }

    // Append the redirect URL to the network's redirectUri if it exists
    if (redirectUrl) {
      const separator = finalRedirectUri.includes('?') ? '&' : '?';
      finalRedirectUri += `${separator}redirect=${encodeURIComponent(redirectUrl)}`;
      console.log('[INFO] Including redirect in domain change:', finalRedirectUri);
    }

    window.location.replace(finalRedirectUri);
  }
  //#endregion

  return {
    isRedirectBlocked,
    shouldRedirect,
    redirectToCustomDomain,
  };
}
